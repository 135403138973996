import React, { PureComponent } from 'react'
import { injectIntl } from 'react-intl'
import { graphql, StaticQuery } from 'gatsby'
import { ToastContainer, toast } from 'react-toastify'
import styled from 'styled-components'

import 'react-toastify/dist/ReactToastify.min.css'
import '../styles/toastify.css'

// Occasionally check the following github issue
// to see the status of optional fields support
// https://github.com/angeloashmore/gatsby-source-prismic/issues/78

const addInterval = ({ date, interval }) => {
  const result = new Date(date)
  INTERVAL_FUNCS[interval](result)
  return result
}

const INTERVAL_FUNCS = {
  '7 days': d => d.setDate(d.getDate() + 7),
  '1 hour': d => d.setHours(d.getHours() + 1),
  '1 day': d => d.setDate(d.getDate() + 1),
  '1 month': d => d.setMonth(d.getMonth() + 1),
}

const Toast = styled.div`
  user-select: none;
`

class Notifications extends PureComponent {
  _showNotifications() {
    const { edges, locale } = this.props
    const now = new Date()
    const notifications = edges.filter(
      ({
        node: {
          lang,
          data: { expiration_date },
        },
      }) => lang === locale && now < Date.parse(expiration_date)
    )

    notifications.forEach(
      ({
        node: {
          prismicId,
          data: { content, interval, autoclose_delay },
        },
      }) => {
        const status = JSON.parse(window.localStorage.getItem('notifications'))
        if (status && status[prismicId]) {
          const lastSeenDate = new Date(status[prismicId].lastSeen)
          const nextApperanceDate = addInterval({
            date: lastSeenDate,
            interval,
          })
          if (nextApperanceDate > now) {
            return
          }
        }

        window.localStorage.setItem(
          'notifications',
          JSON.stringify({
            ...status,
            [prismicId]: {
              lastSeen: now,
            },
          })
        )

        toast.info(
          <Toast dangerouslySetInnerHTML={{ __html: content.html }} />,
          {
            autoClose: autoclose_delay || false,
            className: 'Toastify__toast--vanfactory',
          }
        )
      }
    )
  }

  componentDidMount() {
    this._showNotifications()
  }

  componentDidUpdate() {
    this._showNotifications()
  }

  render() {
    return <ToastContainer />
  }
}

export default injectIntl(({ intl }) => (
  <StaticQuery
    query={graphql`
      {
        allPrismicNotification {
          edges {
            node {
              prismicId
              lang
              data {
                content {
                  html
                }
                interval
                autoclose_delay
                expiration_date
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Notifications
        locale={intl.locale}
        edges={data.allPrismicNotification.edges}
      />
    )}
  />
))
