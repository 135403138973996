module.exports = {
  home: 'Home',
  about: 'About',
  gallery: 'Gallery',
  contact: 'Contact',
  welcomeTo: 'Welcome to',
  sale: 'Sale',
  rental: 'Rental',
  manufacture: 'Manufacture',
  contactUs: 'Contact Us',
  contactForm: 'Send enquiry',
  namePlaceholder: 'Name',
  emailPlaceholder: 'Email',
  enquiryPlaceholder: 'Enquiry ...',
  submit: 'Submit',
  copyright: '© 2023 Van Factory. All Rights Reserved.',
  galleryOther: 'Custom interiors',
  model: 'Model',
  price: 'Price',
  filters: 'Filters',
  manufacturers: 'Manufacturers',
  filterButtonCaption: 'Filter',
  fromPricePlaceholder: 'from',
  toPricePlaceholder: 'to',
  description: 'Description',
  specification: 'Specification',
  equipment: 'Equipment',
  additionalEquipment: 'Additional equipment',
  comments: 'Comments',
}
