import React from 'react'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'

const Container = styled.footer`
  flex: 0 0 ${props => props.theme.footerHeight};

  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid ${props => props.theme.colors.orange};
  color: ${props => props.theme.colors.white};
  font-size: 0.7rem;
  user-select: none;
`

const Footer = () => (
  <Container>
    <FormattedMessage id="copyright" />
  </Container>
)

export default Footer
