import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { PageContext } from '../components/layout'
import { Link } from '../i18n'

const Container = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 auto;
`

const LinkWithActiveClass = props => (
  <Link activeClassName="active" {...props} />
)
const NavLink = styled(LinkWithActiveClass)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  font-weight: 500;
  padding: 0px 30px;

  font-size: 0.9rem;
  text-transform: uppercase;

  & + & {
    border-left: 2px solid ${props => props.theme.colors.white20alpha};
  }

  &:hover,
  &.active {
    color: ${props => props.theme.colors.orange};
  }
`

const Navigation = () => (
  <Container>
    <PageContext.Consumer>
      {pageContext => (
        <>
          <NavLink to="/">
            <FormattedMessage id="home" />
          </NavLink>
          <NavLink to="/about/">
            <FormattedMessage id="about" />
          </NavLink>
          <NavLink to="/sale/">
            <FormattedMessage id="sale" />
          </NavLink>
          {pageContext.locale === 'pl' && (
            <NavLink to="/manufacture/">
              <FormattedMessage id="manufacture" />
            </NavLink>
          )}
          <NavLink to="/gallery/">
            <FormattedMessage id="gallery" />
          </NavLink>
          <NavLink to="/contact/">
            <FormattedMessage id="contact" />
          </NavLink>
        </>
      )}
    </PageContext.Consumer>
  </Container>
)

export default Navigation
