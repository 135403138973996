module.exports = {
  home: 'Start',
  about: 'O nas',
  gallery: 'Galeria',
  contact: 'Kontakt',
  welcomeTo: 'Witaj w',
  sale: 'Sprzedaż',
  rental: 'Wynajem',
  manufacture: 'Produkcja',
  contactUs: 'Kontakt',
  contactForm: 'Wyślij zapytanie',
  namePlaceholder: 'Imię',
  emailPlaceholder: 'Email',
  enquiryPlaceholder: 'Zapytanie ...',
  submit: 'Wyślij',
  copyright: '© 2023 Van Factory. Wszelkie prawa zastrzeżone.',
  galleryOther: 'Zabudowy indywidualne',
  model: 'Model',
  price: 'Cena',
  filters: 'Filtry',
  manufacturers: 'Producenci',
  filterButtonCaption: 'Filtruj',
  fromPricePlaceholder: 'od',
  toPricePlaceholder: 'do',
  description: 'Opis',
  specification: 'Specyfikacja',
  equipment: 'Wyposażenie podstawowe',
  additionalEquipment: 'Dodatkowe wyposażenie',
  comments: 'Komentarze',
}
