import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Link } from '../i18n'
import logoSVG from '../images/logo.svg'

// TODO: clean the mess with theme variables like logoWidth
const Container = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: ${props => props.theme.logoWidth};
  height: 100%;
  background: ${props => props.theme.colors.orange};

  ${media.lessThan('small')`
    width: 175px;
  `}
`

const Image = styled.img`
  display: block;

  ${media.lessThan('small')`
    width: 140px;
  `}
`

const Logo = () => (
  <Container>
    <Link to="/">
      <Image src={logoSVG} alt="VanFactory logo" />
    </Link>
  </Container>
)
export default Logo
