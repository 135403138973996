// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-i-18-n-redirect-js": () => import("./../../../src/i18n/redirect.js" /* webpackChunkName: "component---src-i-18-n-redirect-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacture-js": () => import("./../../../src/pages/manufacture.js" /* webpackChunkName: "component---src-pages-manufacture-js" */),
  "component---src-pages-sale-js": () => import("./../../../src/pages/sale.js" /* webpackChunkName: "component---src-pages-sale-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-subgallery-js": () => import("./../../../src/templates/subgallery.js" /* webpackChunkName: "component---src-templates-subgallery-js" */)
}

