import React from 'react'
import Link from 'gatsby-link'
import { injectIntl } from 'react-intl'

import { languages } from './locales'

const LocalizedLink = ({ to, intl: { locale }, ...props }) => {
  // TODO: check behaviour on 404
  const langCode = languages[locale].code
  const localizedTo = locale ? `/${langCode}${to}` : `${to}`

  return <Link hrefLang={locale} to={localizedTo} {...props} />
}

export default injectIntl(LocalizedLink)
