module.exports = {
  home: 'Start',
  about: 'Über uns',
  gallery: 'Galerie',
  contact: 'Kontakt',
  welcomeTo: 'Willkommen bei',
  sale: 'Verkauf',
  rental: 'Vermietung',
  manufacture: 'Produktion',
  contactUs: 'Kontakt',
  contactForm: 'Womit können wir Ihnen behilflich sein?',
  namePlaceholder: 'Vorname',
  emailPlaceholder: 'E-Mail',
  enquiryPlaceholder: 'Anfrage ...',
  submit: 'Senden',
  copyright: '© 2023 Van Factory. Alle Rechte vorbehalten.',
  galleryOther: 'Maßgeschneiderte Innenräume',
  model: 'Modell',
  price: 'Preis',
  filters: 'Filter',
  manufacturers: 'Produzent',
  filterButtonCaption: 'Filter',
  fromPricePlaceholder: 'ab',
  toPricePlaceholder: 'bis',
  description: 'Beschreibung',
  specification: 'Spezifikation',
  equipment: 'Serienausstattung',
  additionalEquipment: 'Zusatzeinrichtung',
  comments: 'Kommentare',
}
