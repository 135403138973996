import React from 'react'
import media from 'styled-media-query'
import styled from 'styled-components'

import Navigation from './navigation'
import Logo from './logo'
import Flags from './flags'

const Container = styled.header`
  display: flex;
  height: ${props => props.theme.headerHeight};
  background: ${props => props.theme.colors.black};

  flex: 0 0 auto;
  user-select: none;

  ${media.lessThan('small')`
    height: ${props => props.theme.headerHeightMobile};
  `}
`

const DesktopOnly = styled.div`
  display: flex;
  width: 100%;

  ${media.lessThan('large')`
    display: none;
  `}
`

const Header = () => (
  <Container>
    <Logo />
    <DesktopOnly>
      <Navigation />
      <Flags />
    </DesktopOnly>
  </Container>
)

export default Header
