module.exports = {
  localeData: [
    ...require('react-intl/locale-data/pl'),
    ...require('react-intl/locale-data/en'),
    ...require('react-intl/locale-data/de'),
  ],

  // https://www.science.co.il/language/Locale-codes.php
  // code in ISO 639-1
  languages: {
    pl: { text: 'Polski', code: 'pl', flag: 'pl.svg' },
    'en-gb': {
      text: 'English',
      code: 'en',
      flag: 'gb.svg',
      excludedPages: ['/manufacture/'],
    },
    'de-de': {
      text: 'Deutsch',
      code: 'de',
      flag: 'de.svg',
      excludedPages: ['/manufacture/'],
    },
  },
}
