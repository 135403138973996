import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'

import Notifications from './notifications'
import Header from './header'
import BackgroundImage from './background'
import Footer from './footer'
import MobileMenu from './MobileMenu'
import { withIntl } from '../i18n'

import '../styles/index.css'
import '../styles/fonts.css'
import 'react-image-gallery/styles/css/image-gallery.css'

const theme = {
  colors: {
    orange: '#fa7c1d',
    black: '#2a2a2a',
    blackOverlay: 'rgba(24, 26, 29, 0.85)',
    blackAlpha: 'rgba(0, 0, 0, 0.4)',
    white: '#ffffff',
    white20alpha: 'rgba(255, 255, 255, 0.2)',
  },
  headerHeight: '75px',
  headerHeightMobile: '55px',
  footerHeight: '30px',
  logoWidth: '268px',
  fontFamily: 'Roboto, sans-serif',
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  height: 100vh;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;
  flex: 0 1 100%;
  overflow: auto;
`

const OuterPageWrap = styled.div`
  font-family: ${props => props.theme.fontFamily};
  line-height: 1.5;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 1 100%;
  overflow: auto;
`

export const PageContext = React.createContext()

const Layout = ({ children, pageContext }) => (
  <PageContext.Provider value={pageContext}>
    <ThemeProvider theme={theme}>
      <OuterPageWrap id="outerPageWrap">
        <MobileMenu
          pageWrapId="pageWrap"
          outerPageWrap="outerPageWrap"
          theme={theme}
        />
        <Container id="pageWrap">
          {pageContext.routed && <Notifications />}
          <Header />
          <Main>
            <BackgroundImage />
            <ContentWrapper>{children}</ContentWrapper>
            <Footer />
          </Main>
        </Container>
      </OuterPageWrap>
    </ThemeProvider>
  </PageContext.Provider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withIntl(Layout)
