import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { PageContext } from '../components/layout'
import { languages } from '../i18n'

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 35px;
`

const FlagImage = styled.img`
  height: 18px;
  width: 25px;

  &:hover {
    cursor: pointer;
  }

  &:not(:last-of-type) {
    margin-right: 10px;
  }
`

const Flags = () => (
  <LanguageContainer>
    <PageContext.Consumer>
      {pageContext =>
        Object.entries(languages).map(([lcid, lang]) => (
          <FlagImage
            src={require(`../images/flags/${lang.flag}`)}
            alt={lang.text}
            onClick={() =>
              navigate(`/${lang.code}${pageContext.originalPath}`, {
                replace: true,
              })
            }
            key={lcid}
          />
        ))
      }
    </PageContext.Consumer>
  </LanguageContainer>
)

export default Flags
