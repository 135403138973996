import React from 'react'
import { IntlProvider, addLocaleData } from 'react-intl'

import { localeData } from './locales'

addLocaleData(localeData)

export default ComposedComponent => props => {
  const locale = props.pageContext.locale || 'pl'
  const messages = require(`./locales/${locale}.js`) // eslint-disable-line

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      textComponent={React.Fragment}
    >
      <ComposedComponent {...props} />
    </IntlProvider>
  )
}
