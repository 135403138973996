import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const FullScreenImg = styled(Img)`
  height: 100%;
  width: 100%;

  z-index: -1;
  user-select: none;

  & img {
    object-position: center bottom !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: ${props => props.theme.colors.blackAlpha};
  }
`

const BackgroundImage = () => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "background-new.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <FullScreenImg
          style={{ position: 'absolute' }}
          fluid={data.backgroundImage.childImageSharp.fluid}
        />
      </>
    )}
  />
)
export default BackgroundImage
