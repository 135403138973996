import React from 'react'
import { reveal as Menu } from 'react-burger-menu'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import media from 'styled-media-query'

import { PageContext } from '../components/layout'
import { Link } from '../i18n'
import Flags from './flags'

const LinkWithActiveClass = props => (
  <Link activeClassName="active" {...props} />
)
const NavLink = styled(LinkWithActiveClass)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
  font-weight: 500;
  padding: 10px 30px;

  font-size: 1.2rem;
  text-transform: uppercase;
  transition: background 0.3s;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.orange};
  }
  &:hover {
    background: rgba(0, 0, 0, 0.2);
  }
`

const StyledMenu = styled(Menu)`
  background: ${props => props.theme.colors.black};
  padding-top: 20px;
`

const MobileContainer = styled.div`
  ${media.greaterThan('large')`
    display: none;
  `}

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 25px;
    top: calc((${props => props.theme.headerHeight} - 30px) / 2);

    ${media.lessThan('small')`
      height: 25px;
      top: calc((${props => props.theme.headerHeightMobile} - 25px) / 2);
    `}
  }

  .bm-burger-bars {
    background-color: ${props => props.theme.colors.white};
    height: 15% !important;
  }
`

const MobileFlagsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  right: 0;
`

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  render() {
    const { pageWrapId, outerWrapId } = this.props

    return (
      <PageContext.Consumer>
        {pageContext => (
          <MobileContainer>
            <StyledMenu
              pageWrapId={pageWrapId}
              outerContainerId={outerWrapId}
              isOpen={this.state.menuOpen}
              onStateChange={state => this.handleStateChange(state)}
              right
            >
              <NavLink to="/" onClick={() => this.closeMenu()}>
                <FormattedMessage id="home" />
              </NavLink>
              <NavLink to="/about/" onClick={() => this.closeMenu()}>
                <FormattedMessage id="about" />
              </NavLink>
              <NavLink to="/sale/" onClick={() => this.closeMenu()}>
                <FormattedMessage id="sale" />
              </NavLink>
              {pageContext.locale === 'pl' && (
                <NavLink to="/manufacture/">
                  <FormattedMessage id="manufacture" />
                </NavLink>
              )}
              <NavLink to="/gallery/" onClick={() => this.closeMenu()}>
                <FormattedMessage id="gallery" />
              </NavLink>
              <NavLink to="/contact/" onClick={() => this.closeMenu()}>
                <FormattedMessage id="contact" />
              </NavLink>
              <MobileFlagsContainer>
                <Flags />
              </MobileFlagsContainer>
            </StyledMenu>
          </MobileContainer>
        )}
      </PageContext.Consumer>
    )
  }
}

export default MobileMenu
